h1,
.h1 {
  margin-top: 7px;
  //color: $gray-600;
}

// Blockquote styling for Markdown

blockquote,
.blockquote {
  @extend .blockquote;
  font-size: 0.95rem;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote::before,
.blockquote::before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote::after,
.blockquote::after {
  color: #ccc;
  content: no-close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.navbar-brand:link {
  font-size: 2.5em;
  padding-bottom: 0;
}

.navbar {
  opacity: 0.85;
  z-index: 1000;
}
